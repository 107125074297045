import React from 'react'
import Lsb from './Global-content/Lsb'
import Herosec from './Main-Components/Herosec'
import About from './Main-Components/About'
import Resume from './Main-Components/Resume'
import Service from './Main-Components/Service'
import Skills from './Main-Components/Skills'
import Portfolio from './Main-Components/Portfolio'
import Testimonials from './Main-Components/Testimonials'
import Achievements from './Main-Components/Achievements'
import Clientlogos from './Main-Components/Clientlogos'
import Blogs from './Main-Components/Blogs'
import Contact from './Main-Components/Contact'

export default function Main(props) {
  const website_data = props.website_data;
  return (
    <main className="drake-main">
        <div id="smooth-wrapper">
            <div id="smooth-content">

                <Lsb website_data={website_data}/> {/* this component will used by mobile devices */}

                <Herosec main_data={website_data.main}/>
                <About about_data={website_data.about}/>
                <Service service_data={website_data.services}/>
                <Skills skill_data={website_data.skill}/>
                <Testimonials testimonial_data={website_data.testimonials}/>
                <Achievements achievement_data={website_data.achievements}/>
                <Resume experience_data={website_data.experience}/>
                <Portfolio portfolio_data={website_data.project}/>
                <Clientlogos client_logo_data={website_data.logo}/>
                <Blogs blog_data={website_data.blogs}/>
                <Contact website_data={website_data}/>
                
            </div>
        </div>
    </main>
  )
}
