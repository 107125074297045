import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function About(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const about_data = (props.about_data ? props.about_data[0] : {});

  return (
    <section className="about-area page-section scroll-to-page" id="about">
        <div className="custom-container">
            <div className="about-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="lar la-user"></i> About
                    </h4>
                    <h1 className="scroll-animation about-title" data-aos='fade-up'>{about_data['title']}</h1>
                </div>
                <p className="scroll-animation about-description" data-aos='fade-up' dangerouslySetInnerHTML={{__html: about_data['description']}}></p>
            </div>
        </div>
    </section>
  )
}
