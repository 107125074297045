import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { Link } from 'react-router-dom'

export default function Blogs(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const blog_data = props.blog_data ?? [];

    console.log(blog_data);

    const blogs_list = blog_data.map((blogs) => {
        return <div className="col-md-6 scroll-animation mb-4" data-aos='fade-up' key={blogs.uid}>
            <div className="blog-table">
                <div className="blog-table-header">
                    <div className="top d-flex justify-content-between align-items-start">
                        <h5>{blogs.mbs_title}</h5>
                        <p className="text-right">{blogs.crdt_dt_format}</p>
                    </div>
                    <h6>{blogs.title}</h6>
                </div>
                <p className="blog-table-desc">{blogs.description}</p>

                <Link to={'blogs/'+blogs.mbc_slug+'/'+blogs.mbs_slug+'/'+blogs.slug} className="theme-btn">
                    Read More ...
                </Link>
            </div>
        </div>
    })

  return (
    <section className="blog-area page-section scroll-to-page" id="blog">
            <div className="custom-container">
                <div className="blog-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-dollar-sign"></i> Blogs
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Blogs</span></h1>
                    </div>

                    <div className="blog-table-items">
                        <div className="row">
                            {blogs_list}
                        </div>
                    </div>

                </div>
            </div>
    </section>
  )
}
