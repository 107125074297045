import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const experience_data = props.experience_data ?? [];

    const experience_list = experience_data.map((experience) =>
        <div className="item scroll-animation" data-aos='fade-right' key={experience.uid}>
            <span className="date">{experience.date_range}</span>
            <h2>{experience.title}</h2>
            <p>{experience.subtitle}</p>
        </div>
    )

  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    { experience_list }
                </div>

            </div>
        </div>
    </section>
  )
}
