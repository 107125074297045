import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Skills(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const skill_data = props.skill_data ?? [];

    const skill_list = skill_data.map((skill) =>
        <div className="col-md-3 scroll-animation" data-aos='fade-right' key={skill.uid}>
            <div className="skill">
                <div className="skill-inner">
                    <i className={skill.image_link}></i>
                {/* <img src="../assets/images/figma.png" alt="Figma"/> */}
                    <h1 className="percent">{skill.value}%</h1>
                </div>
                <p className="name">{skill.title}</p>
            </div>
        </div>
    )
  return (
    <section className="skills-area page-section scroll-to-page" id="skills">
            <div className="custom-container">
                <div className="skills-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="las la-shapes"></i> my skills
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>My <span>Advantages</span></h1>
                    </div>

                    <div className="row skills text-center">
                        {skill_list}
                    </div>    
                </div>
            </div>
    </section>
  )
}
