import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Achievements(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const achievement_data = props.achievement_data ?? [];

    const achievement_list = achievement_data.map((achievement) =>
        <div className="col-md-3 scroll-animation mb-3" data-aos='fade-right' key={achievement.uid}>
            <div className="achievement">
                <div className="achievement-inner">
                    <img src={'../assets/images/achievements/'+achievement.order+(achievement.value == 1 ? '_' : '')+'.webp'} alt="Figma"/>
                </div>
                <small className="name mt-2">{achievement.title}</small>
            </div>
        </div>
    )
  return (
    <section className="achievements-area page-section scroll-to-page" id="achievements">
        <div className="custom-container">
            <div className="achievements-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-shapes"></i> my achievements
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Advantages</span></h1>
                </div>

                <div className="row achievements text-center">
                    {achievement_list}
                </div>    
            </div>
        </div>
    </section>
  )
}
