import React, { useState, useEffect } from 'react'
import Video from './Global-content/Video'
import Pageloader from './Global-content/Pageloader'
import Rsidemenu from './Global-content/Rsidemenu'
import Scrollnav from './Global-content/Scrollnav'
import Lsb from './Global-content/Lsb'
import Settings from './Global-content/Settings'
import MainWrapper from './MainWrapper'
import {Helmet} from 'react-helmet'

export default function Global(){
  const [currentVideo, setVideo]=useState('')
  const [detail_data, setData]=useState({})
  const [seo_data, setSeo]=useState([])
  const [contact_data, setContact]=useState([])

  useEffect(() => {
    send();
    changeVideo('./assets/images/video1.mp4');

    const script = document.createElement('script');

    script.src = 'https://embed.tawk.to/660adbcca0c6737bd1271768/1hqd6l6h0';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

  }, []);
    
  const changeVideo = (videos) => {
    setVideo(videos);
  }

  const send = async () => {
    await fetch('https://api.ankushgautam.in/api/website/details')
    // await fetch('http://localhost/api.codyx.me/api/website/details')
      .then(response => response.json())
      .then(json => { 
        setData(json.data);
        setSeo(json['data'].seo);
        setContact(json['data'].contact);

        if(document.querySelector(".page-loader"))
          document.querySelector(".page-loader").remove();
      })
      .catch(error => console.error(error));
  }

  var seo_list = [];
  seo_data.map((seo) => {
    seo_list[seo.sub_group] = seo;
  });

  var contact_list = [];
  contact_data.map((contact) => {
    contact_list[contact.sub_group] = contact;
  });

  return (
    <div>
      <Helmet>
        
        {/* <title>{seo_list['seo_title']?.remark}</title>

        <link rel="canonical" href={seo_list['seo_url']?.remark} />

        <meta name="description" content={seo_list['seo_description']?.remark} />
        <meta name="keywords" content={seo_list['seo_keyword']?.remark} />
        <meta name="author" content={seo_list['seo_author']?.remark} />

        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />

        <meta property="og:title" content={seo_list['seo_title']?.remark} />
        <meta property="og:url" content={seo_list['seo_url']?.remark} />
        <meta property="og:site_name" content={seo_list['seo_title']?.remark} />
        <meta property="og:description" content={seo_list['seo_description']?.remark} />
        <meta property="og:image" content="./assets/images/ankush-gautam.PNG" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo_list['seo_title']?.remark} />
        <meta name="twitter:description" content={seo_list['seo_description']?.remark} />

        <meta name="copyright" content={contact_list['self_name']?.title} />
        <meta name="reply-to" content={contact_list['self_email']?.title} /> */}

      </Helmet>

      {/* <Video video={currentVideo}/> */}
      <Pageloader/>
      {/* Settings icon */}
      {/* <Settings clickEvent={changeVideo} /> */}

      {/* Left Side Bar and it will get executed on large devices*/}
      <Lsb website_data={detail_data} />

      {/*when user interacts with the hamburger menu it display the inner-components*/}
      <Rsidemenu/>

      {/*fixed on large screens easy to navigate through section og main components*/}
      <Scrollnav/>

      {/* heroSection, about, testimoni  */}
      <MainWrapper website_data={detail_data} />

  </div>
  )
}