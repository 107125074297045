import React from 'react'
import self_img from '../../assets/images/ankush-gautam.PNG';
import logo from '../../assets/images/logo.png';

export default function Lsb(props) {
    const contact_data = props['website_data'].contact ?? [];
    const main_data = props['website_data'].main ?? [];

    var contact_list = [];
    contact_data.map((contact) => {
        contact_list[contact.sub_group] = contact;
    });

    var main_list = [];
    main_data.map((main) => {
        main_list[main.sub_group] = main;
    });

    return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
                <img src={logo} alt="Logo"/>
                <span className="designation">{contact_list['self_designation']?.title}</span>
            </div>
            <img className="me" src={self_img} alt="Me"/>
            <h3 className="email">{contact_list['self_email']?.title}</h3>
            <h2 className="email">{contact_list['self_mobile']?.title}</h2>
            <h4 className="address">{contact_list['self_address']?.title}</h4>
            <a href="#" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me @ <b>${main_list['self_costing']?.title}</b> / HR
            </a>
            <h2 className='mt-3 text-center'></h2>
        </div>
    )
}   
