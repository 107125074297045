import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service(props) {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])

    const service_data = props.service_data ?? [];

    const service_list = service_data.map((services) =>
        <div className="service-item scroll-animation" data-aos='fade-up' key={services.uid}>
            <i className={'las la-'+services.image_link}></i>
            <h2>{services.title}</h2>
            <p>{services.subtitle}</p>
            <span className="projects">{services.value} Projects</span>
        </div>
    )

  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
                    {service_list}
                </div>    
            </div>
        </div>
    </section>
  )
}
