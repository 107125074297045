import React, { useRef, useEffect, useState } from "react";

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Testimonials(props) {
    
    const carouselRef = useRef(null);

    useEffect(() => {
        AOS.init({duration:1000})
    }, [])

    var active_testimonial = 1;

    const handlePrevClick = () => {
        console.log(active_testimonial);
        carouselRef.current.prev();
        active_testimonial--;
    }

    const handleNextClick = () => {
        carouselRef.current.next();
        active_testimonial++;
    }

    const createStar = (count) => {
        var arr = [];
        for (let i = 0; i < 5; i++) {
            if(i < count)
                arr.push(<i className="la la-star" key={'i_'+i}></i>);
            else
                arr.push(<i className="la la-star-o" key={'i_'+i}></i>);
        }
        return arr;
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const handleAfterChange = (current) => {
        setActiveIndex(current);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: handleAfterChange, // Handle slide change
    };

    const testimonial_data = props.testimonial_data ?? [];

    const testimonial_list = testimonial_data.map((testimonial) =>

        <div className="testimonial-item" key={testimonial.uid}>
            <div className="testimonial-item-inner">
                <div className="author d-flex align-items-center">
                    <img src={'https://lh3.googleusercontent.com/a-/'+testimonial.image_link} alt="portfolio"/>
                    <div className="right">
                        <h3>{testimonial.title}</h3>
                        <p className="designation" dangerouslySetInnerHTML={{__html: testimonial.subtitle}}></p>
                    </div>
                </div>
                <p>“{testimonial.remark}”</p>
                <a href="" className="project-btn testimonial-stars">
                    <span>{createStar(testimonial.value)}</span> ( {testimonial.value} Stars )
                </a>
            </div>
        </div>
    )
  
  return (
    <section className="testimonial-area page-section scroll-to-page" id="testimonial">
            {/* <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script> */}

            {/* <script src="https://code.jquery.com/jquery-3.6.4.slim.min.js" integrity="sha256-a2yjHM4jnF9f54xUQakjZGaqYs/V1CYvWpoqZzC2/Bw=" crossorigin="anonymous"></script> */}

            <div className="custom-container">
                <div className="testimonial-content content-width">
                    <div className="section-header">
                        <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                            <i className="lar la-comment"></i> testimonial
                        </h4>
                        <h1 className="scroll-animation" data-aos='fade-up'>Trusted by <span>Hundered Clients</span></h1>
                    </div>    
                    <div className="testimonial-slider-wrap scroll-animation" data-aos='fade-up'>
                        <Slider {...settings}>
                            {testimonial_list}
                        </Slider>
                        <div className="testimonial-footer-nav">
                            <div className="testimonial-nav d-block align-items-center text-center">
                                <div id="testimonial-slide-count"><span className="left">{activeIndex + 1}</span> / {testimonial_list.length}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>
  )
}